@use '@/styles/utils/mixins.scss' as *;

.iconCardWrapper {
  display: flex;
  flex-direction: column;
  gap: 3.75rem;

  @include mob() {
    gap: 2.5rem;
  }

  .iconCardContentContainer {
    display: flex;
    gap: 3.75rem;
    flex-direction: column;
  }

  .iconCardContentDetails {
    max-width: 38.75rem;
    width: 100%;
  }

  .iconCardLists {
    position: relative;

    .iconCard {
      display: flex;
      gap: 0.75rem;
      align-items: flex-start;
      padding: 1.5rem 1.25rem;

      @include mob() {
        flex-direction: column;
        align-items: flex-start;
        padding: 1.5rem 0rem;
      }

      .iconCardBody {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
      }
      .title {
        margin-top: 0.5rem;
        font-size: 1rem;
        font-weight: bold;
        color: $dark;
      }
      .description {
        color: $dark;
      }
    }

    .desktopGrid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 2.5rem;

      @include des() {
        grid-template-columns: repeat(2, 1fr);
      }

      @include mob() {
        grid-template-columns: repeat(1, 1fr);
      }
    }

    .swiper {
      display: none;

      @include mob() {
        display: block;
      }

      :global {
        .swiper-slide {
          flex: 0 0 auto;
        }
      }

      .iconCardSlide {
        display: flex;
        flex-direction: column;
        gap: 1rem;
      }
    }
  }

  .swipeWrapper {
    display: none;
    justify-content: flex-end;
    @include mob() {
      display: flex;
    }

    .swiperIconBtn {
      display: flex;
      align-items: center;
      gap: 1.25rem;
      border: none;
      background-color: transparent;
      cursor: pointer;
      color: $primary;
      font-weight: 500;
      transition: all 0.2s ease-in-out;

      img {
        transition: all 0.2s ease-in-out;
      }

      &:hover,
      &:focus,
      &:active {
        color: $primaryAlt;
        img {
          opacity: 0.5;
        }
      }
    }
  }
}
